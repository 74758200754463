<template>
  <div>
    <el-dialog
      append-to-body
      v-bind="{ ...$attrs, 'custom-class': `${$attrs['custom-class'] ? $attrs['custom-class'] : ''} dialog-center` }"
      v-on="$listeners"
      :title="title"
      :visible.sync="openCardDialog">
      <div class="dialog-center-main">
        <slot></slot>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '弹窗标题'
    }
  },
  computed: {
    openCardDialog: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  }
}
</script>

<style lang="scss" >
.dialog-center {
  &.el-dialog {
    margin-top: 0 !important;
    top: 50%;
    transform: translateY(-50%);
  }
  &-main {
    max-height: 70vh;
    overflow-y: auto;
  }
}
</style>
